import React, { useEffect, useState } from "react";

import InputSelect from "../../../components/InputSelect";
import Input from "../../../components/Input";
import InputRadio from "../../../components/InputRadio";
import Button from "../../../components/Button";
import Modal from "../../../components/Modal";
import { renderError } from "../../../helpers/errors";
import api from "../../../services/api";
import { createOptions, createProductOptions } from '../../../helpers/selects';
import { convertIntToMoney, convertMoenyToInt } from "../../../helpers/conversions";
import CustomizationSection from "./CustomizationSection";
import ParamSection from "./ParamSection";
import { Calculation } from "./calculationPrice";
import { calculateCustomizationTotal } from "./customizationCalculate";
import { ModalDelete } from "../../../components/ModalDelete";
import { toast } from "react-toastify";
import SelectBillingCompany from "../../../components/selects/SelectBillingCompany";
import SelectProduct from "../../../components/selects/SelectProduct";

const calculationTypeOptions = [
    { label: 'Pré-cadastrado', value: 'automatic' },
    { label: 'Manual', value: 'manual' }
];

const ModalProduct = ({close, quote, itemToEdit, selectedBillingCompany, setSelectedBillingCompany, getQuote, updateQuote}) => {
    const [loading, setLoading] = useState(true);
    const [params, setParams] = useState([]);
    const [customizations, setCustomizations] = useState([]);
    const [products, setProducts] = useState([]);
    const [subproducts, setSubproducts] = useState([]);

    const [gettingPrice, setGettingPrice] = useState(false);
    
    const [product, setProduct] = useState(null);
    const [stock, setStock] = useState(0);

    const [selectedProduct, setSelectedProduct] = useState(null);
    const [selectedSubproduct, setSelectedSubproduct] = useState(null);

    const [companiesProduct, setCompaniesProduct] = useState([]);
    const [selectedCompanyProduct, setSelectedCompanyProduct] = useState(null);

    const [productCalculationType, setProductCalculationType] = useState('automatic');
    const [productQuantity, setProductQuantity] = useState(1);
    const [productQuantityColors, setProductQuantityColors] = useState(0);
    const [productUnitCost, setProductUnitCost] = useState('0,00');
    const [productUnitCostError, setProductUnitCostError] = useState('');
    const [productTotalCost, setProductTotalCost] = useState('0,00');
    const [productSupplierCost, setProductSupplierCost] = useState(0);

    const [productCustomizations, setProductCustomizations] = useState([]);
    const [productParams, setProductParams] = useState([]);

    const [formulaPrice, setFormulaPrice] = useState('');
    const [formulaCommission, setFormulaCommission] = useState('');

    const [salePrice, setSalePrice] = useState('R$ 0,00');
    const [totalSalePrice, setTotalSalePrice] = useState('R$ 0,00');
    const [commission, setCommission] = useState('R$ 0,00');

    const [gettingBillingCompanies, setGettingBillingCompanies] = useState(true);
    const [billingCompanies, setBillingCompanies] = useState([]);

    const [showDelete, setShowDelete] = useState(false);

    const [subproductPrices, setSubproductPrices] = useState([]);

    useEffect(() => {
        getProduct();
        getParams();
        getCustomizations();
        getCompaniesProduct();
        getBillingCompanies();
    }, []);

    useEffect(() => {
        getSubproducts();
    }, [products, selectedProduct]);

    useEffect(() => {
        getPrices();
    }, [selectedSubproduct]);
    
    useEffect(() => {
        getProductUnitCost();
        getCompaniesProduct();
    }, [subproductPrices]);

    const getBillingCompanies = () => {
        setGettingBillingCompanies(true);

        api.get(`/company?filters[0][type][contain]=billing`).then(res => {
            setBillingCompanies(res.data.resources);
        }).catch(error => {
            renderError(error);
        }).then(() => setGettingBillingCompanies(false));
    }
    
    const handleProductChange = productId => {
        setSelectedSubproduct(null);
        setCompaniesProduct([]);
        setSelectedCompanyProduct(null);
        setSelectedProduct(productId);
    }

    const getSubproducts = () => {
        let toSubproducts = [];
        let product = products.filter(product => product.id === selectedProduct)[0];
        if(!product) return;
        product.subproducts.map(subproduct => {
            toSubproducts.push({
                id: subproduct.id,
                desc: `${subproduct.color.name} - ${subproduct.sku}`
            });
        });
        setSubproducts(toSubproducts);
    }

    useEffect(() => {
        calculateProductTotalCost();
    }, [productQuantity, productUnitCost]);

    useEffect(() => {
        getProductUnitCost();
    }, [selectedCompanyProduct, productQuantity, productCalculationType, selectedSubproduct, selectedProduct, products]);

    useEffect(() => {
        calculatePrice();
    }, [billingCompanies, selectedBillingCompany, productQuantity, productUnitCost, productCustomizations, productParams]);

    useEffect(() => {
        getTotalSalePrice();
    }, [productQuantity, salePrice]);

    useEffect(() => {
        getProductParams();
    }, [params])

    useEffect(() => {
        getSelectedProduct();
    }, [selectedProduct, products]);

    const getSelectedProduct = () => {
        let product = products.filter(product => product.id === selectedProduct);
        if(product.length > 0){
            setProduct(product[0]);
        } else {
            setProduct(null);
        }
    }

    const getProduct = () => {
        if(!itemToEdit) return;
        setSelectedProduct(itemToEdit.subproduct.product_id);
        setSelectedSubproduct(itemToEdit.subproduct_id);
        setSelectedCompanyProduct(itemToEdit.company_id);
        setProductCalculationType(itemToEdit.calculation_type);
        setProductQuantity(itemToEdit.quantity);
        setProductQuantityColors(itemToEdit.quantity_colors);
        setProductUnitCost( `R$ ${convertIntToMoney(itemToEdit.cost)}` );

        getProductCustomizations(itemToEdit.budget_item_customizations);
    }

    const getTotalSalePrice = () => {
        let quantity = productQuantity;
        let price = convertMoenyToInt(salePrice);
        let total = price * quantity;
        total = `R$ ${convertIntToMoney(total)}`;
        setTotalSalePrice(total);
    }

    const calculatePrice = () => {
        if(billingCompanies.length === 0){
            return;
        }
        const taxPercentage = getTaxPercentage();
        const calculation = new Calculation(
            formulaPrice, 
            formulaCommission, 
            productQuantity, 
            productUnitCost, 
            productCustomizations, 
            productParams,
            taxPercentage,
            params
        );

        const toSalePrice = calculation.getSalePrice();
        const toCommission = calculation.getCommission();

        setSalePrice( `R$ ${convertIntToMoney(toSalePrice)}`);
        setCommission( `R$ ${convertIntToMoney(toCommission)}`);
    }

    const getTaxPercentage = () => {
        const company = billingCompanies.filter(billingCompany => billingCompany.id === selectedBillingCompany)[0];
        return company.tax_percentage;
    }

    const addProductCustomization = () => {
        const toProductCustomization = {
            calculationType: 'automatic',
            selectedCustomization: null,
            selectedCompanyCustomization: null,
            quantityColors: productQuantityColors,
            quantity: productQuantity,
            customizationUnitCost: `R$ 0,00`,
            customizationTotalCost: `R$ 0,00`,
            table: product.origin === 'integracao_spot' && product.spot_components.length > 0 ? 'spot' : 'registered',
            product_id: product.id
        };

        setProductCustomizations(prev => [...prev, toProductCustomization]);
    }

    const getCompaniesProduct = async () => {
        let toCompaniesProduct = [];

        subproductPrices.map(price => {
            toCompaniesProduct.push(price.company);
        });
        setCompaniesProduct(toCompaniesProduct);
    }

    const getPrices = () => {
        if(!selectedSubproduct){
            setSubproductPrices([]);
            setStock(0);
            return;
        };

        setGettingPrice(true);
        
        api.get(`/subproduct/${selectedSubproduct}/search`).then(res => {
            let prices = [];
            res.data.resource.prices.map(price => {
                prices.push(price);
            });
            setStock(res.data.resource.stock);
            setSubproductPrices(prices);
        }).catch(error => {
            console.log('error', error);
            renderError(error);
            setSubproductPrices([]);
        }).then(() => setGettingPrice(false));
    }
    
    const getParams = () => {
        api.get('/param').then(res => {
            let toParams = [];
            res.data.resources.map(resource => {
                if(resource.type === 'price_calculation'){
                    setFormulaPrice(resource.value);
                    return;
                }
                if(resource.type === 'commission_calculation'){
                    setFormulaCommission(resource.value);
                    return;
                }
                toParams.push(resource);
            })
            setParams(toParams);
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    const getProductCustomizations = (currentProductCustomizations) => {
        let toProductCustomizations = [];
        currentProductCustomizations.map(current => {
            let total = current.table === 'registered' ? calculateCustomizationTotal(
                current.quantity,
                current.cost,
                current.customization.calculation_per
            ) : 0;
            toProductCustomizations.push({
                calculationType: current.calculation_type,
                selectedCustomization: current.customization_id,
                selectedSupplierCustomization: current.company_id ? current.company_id : current.customer_id,
                selectedSupplierTypeCustomization: current.company_id ? "PJ" : "PF",
                quantityColors: current.quantity_colors,
                quantity: current.quantity,
                customizationUnitCost: `R$ ${convertIntToMoney( current.cost )}`,
                customizationTotalCost: `R$ ${convertIntToMoney( total )}`,
                spot_component: current?.spot_component ?? null,
                spot_location: current?.spot_location ?? null,
                spot_area: current?.spot_area ?? null,
                spot_customization: current?.spot_customization ?? null,
                table: current.table
            });
        });

        setProductCustomizations(toProductCustomizations);
    }

    const getProductParams = () => {
        let toProductParams = [];
        let currentParams = itemToEdit?.params ? JSON.parse( itemToEdit.params ) : [];
        params.map(param => {
            let currentParam = currentParams.filter(currentParam => currentParam.id === param.id);
            if(currentParam.length === 0){
                toProductParams.push({
                    type: param.type,
                    name: param.name,
                    options: param.options,
                    position: param.position,
                    param_id: param.param_id,
                    id: param.id,
                    formula_total_real: param.formula_total_real,
                    formula_total_quote: param.formula_total_quote,
                    value: param.type === 'percentage' && param.value ? param.value : null,
                    values: []
                });
            } else {
                toProductParams.push(currentParam[0]);
            }
        });

        setProductParams(toProductParams);
    }

    const getCustomizations = () => {
        api.get('/customization').then(res => {
            setCustomizations(res.data.resources);
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }
    
    const calculateProductTotalCost = () => {
        let quantity = productQuantity;
        let cost = productUnitCost;
        cost = convertMoenyToInt(cost);
        let total = cost * quantity;
        total = `R$ ${convertIntToMoney(total)}`;
        setProductTotalCost(total);
    }

    const getProductUnitCost = async () => {
        setProductUnitCostError('');

        let error = 'Custo não encontrado no fornecedor. Mude a origem do custo para manual.';

        if(!selectedSubproduct){
            error = 'Informe o produto e a cor para obter o custo.';
        }

        let cost = 0;

        subproductPrices.map(price => {
            if(price.init_quantity <= productQuantity && price.final_quantity >= productQuantity){
                error = '';
                cost = price.price;
            }
        });

        setProductSupplierCost(cost);

        if(productCalculationType === 'manual'){
            setProductUnitCost( `R$ ${convertIntToMoney(itemToEdit.cost)}` );
        } else {
            setProductUnitCost( `R$ ${convertIntToMoney(cost)}` );
            setProductUnitCostError(error);
        }
    }

    const updateProduct = (create = false) => {
        const validation = validate();
        if(!validation) return;

        setLoading(true);

        let itemToStore = {
            budget_id: quote.id,
            subproduct_id: selectedSubproduct,
            company_id: selectedCompanyProduct,
            quantity: productQuantity,
            calculation_type: productCalculationType,
            quantity_colors: 0,
            cost: convertMoenyToInt( productUnitCost ),
            price: convertMoenyToInt( salePrice ),
            params: JSON.stringify( productParams ),
            customizations: getCustomizationsToStore()
        };
        
        api({
            method: create ? 'post' : 'put',
            url: create ? `/budget/item` : `/budget/item/${itemToEdit.id}`,
            data: itemToStore
        }).then(res => {
            updateQuote();
            if(create){
                toast.success('Produto adicionado com sucesso'); 
            } else {
                close();
            }
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    const validate = () => {
        let result = true;
        productParams.map(productParam => {
            if(productParam.value === null){
                let param = params.filter(param => param.id === productParam.id)[0];
                if(param.required){
                    toast.error(`O campo ${param.name} precisa ser informado`);
                    result = false;
                }
            }
        })

        return result;
    }

    const getCustomizationsToStore = () => {
        let customizationsToStore = [];
        productCustomizations.map(productCustomization => {
            customizationsToStore.push({
                customization_id: productCustomization.table === 'registered' ? productCustomization.selectedCustomization : null, 
                company_id: productCustomization.selectedSupplierTypeCustomization === "PJ" ? productCustomization.selectedSupplierCustomization : null,
                customer_id: productCustomization.selectedSupplierTypeCustomization === "PF" ? productCustomization.selectedSupplierCustomization : null,
                quantity_colors: productCustomization.quantityColors,
                quantity: productCustomization.quantity,
                cost: convertMoenyToInt( productCustomization.customizationUnitCost ),
                total_cost: convertMoenyToInt( productCustomization.customizationTotalCost ),
                calculation_type: productCustomization.calculationType,
                table: productCustomization.table,
                spot_component: productCustomization?.spot_component ?? null,
                spot_location: productCustomization?.spot_location ?? null,
                spot_area: productCustomization?.spot_area ?? null,
                spot_customization: productCustomization?.spot_customization ?? null
            });
        });
        return customizationsToStore;
    }

    const deleteProduct = () => {
        api.delete(`/budget/item/${itemToEdit.id}`).then(res => {
            getQuote();
            close();
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    const getSupplierName = () => {

    }

    return (
        <>
            <Modal
                title={`Configuração do produto`}
                size={`big`}
                show={true}
                close={close}
                footer={
                    <>
                        <Input label={`Preço unit.`} value={salePrice} disabled={true} />
                        <Input label={`Total`} value={totalSalePrice} disabled={true} />
                        {/* <Input label={`Comissão`} value={commission} disabled={true} /> */}
                        <Button type={`secondary`} svg={`plus-blue`} text={`Adicionar`} loading={loading} action={() => updateProduct(true)} />
                        {itemToEdit &&
                            <Button type={`primary`}  svg={`save-white`} loading={loading} text={`Atualizar`} action={() => updateProduct()} />
                        }
                    </>
                }
            >
                {((itemToEdit && products.length === 0) || gettingBillingCompanies) && 
                    <p>Carregando...</p>
                }
                {!gettingBillingCompanies && billingCompanies.length === 0 &&
                    <p>Nenhuma empresa de faturamento encontrada</p>
                }
                {}
                {!gettingBillingCompanies && billingCompanies.length > 0 &&
                    <div style={{ display: itemToEdit && products.length === 0 ? 'none' : 'block' }}>
                        <div className="section">
                            <SelectBillingCompany
                                selectedBillingCompany={selectedBillingCompany}
                                setSelectedBillingCompany={setSelectedBillingCompany}
                            />
                        </div>
                        <div className="modal-section-title">
                            <div className="left">
                                <h2>Produto</h2>
                            </div>
                            <div className="right">
                                {/* <InputRadio
                                    className={`no-margin`}
                                    options={calculationTypeOptions}
                                    value={productCalculationType}
                                    change={setProductCalculationType}
                                /> */}
                            </div>
                        </div>
                        <div className="section">
                            <div className="row">
                                <div className="col-4">
                                    <SelectProduct
                                        changeProducts={setProducts}
                                        selectedProduct={selectedProduct}
                                        setSelectedProduct={handleProductChange}
                                    />
                                    {selectedProduct &&
                                        <a href={`/product/edit/${selectedProduct}`} className="link-pos-input" target="_blank">Mais detalhes</a>
                                    }
                                </div>
                                <div className="col-4">
                                    <InputSelect 
                                        label={`Cor`}
                                        options={createOptions(subproducts, 'desc', 'id')} 
                                        value={selectedSubproduct} 
                                        change={setSelectedSubproduct}
                                    />
                                </div>
                                <div className="col-4">
                                    <Input
                                        label={`Fornecedor`}
                                        disabled={true}
                                        value={product?.company_trade_name ?? 'Sem informação'}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-3">
                                    <Input 
                                        label={`Quantidade`}
                                        value={productQuantity}
                                        change={setProductQuantity}
                                        mask={`convertToInt`}
                                    />
                                    {/* <span className="stock">{`Em estoque: ${stock.toLocaleString('pt-BR')}`}</span> */}
                                </div>
                                <div className="col-3">
                                    <InputSelect
                                        label={`Origem do custo`}
                                        options={calculationTypeOptions}
                                        value={productCalculationType}
                                        change={setProductCalculationType}
                                    />
                                </div>
                                <div className="col-3">
                                    <Input
                                        label={`Custo unit.`}
                                        value={gettingPrice ? 'Buscando...' : productUnitCost}
                                        change={setProductUnitCost}
                                        mask={`convertToMoney`}
                                        disabled={productCalculationType === 'automatic'}
                                        error={productUnitCostError}
                                    />
                                </div>
                                <div className="col-3">
                                    <Input
                                        label={`Custo total`}
                                        value={gettingPrice ? 'Buscando...' : productTotalCost}
                                        disabled={true}
                                    />
                                </div>
                            </div>
                        </div>
                        
                        {(customizations.length > 0 || product?.origin === 'integracao_spot') && productCustomizations.map((productCustomization, index) => (

                            <CustomizationSection
                                key={index}
                                index={index}
                                product={product}
                                calculationTypeOptions={calculationTypeOptions}
                                customizations={customizations}
                                productCustomization={productCustomization}
                                productCustomizations={productCustomizations}
                                setProductCustomizations={setProductCustomizations}
                                productQuantity={productQuantity}
                                productSupplierCost={productSupplierCost}
                            />
                        ))}

                        {(customizations.length > 0 || product?.origin === 'integracao_spot') && product &&
                            <Button 
                                type={`link`}
                                size={`small`}
                                full={true}
                                text={`Adicionar gravação`}
                                action={addProductCustomization}
                            />
                        }

                        {params.map((param, index) => (
                            <ParamSection
                                key={index}
                                param={param}
                                productParams={productParams}
                                setProductParams={setProductParams}
                            />
                        ))}
                    </div>
                }
            </Modal>
            <ModalDelete
                action={deleteProduct}
                close={() => setShowDelete(false)}
                show={showDelete}
                loading={loading}
            />
        </>
    );
}

export default ModalProduct;