export const getIndexFromObjectArray = (arr, param, value) => {
    let index = null;
    if(arr){
        arr.map((object, i) => {
            if(object[param] === value){
                index = i;
            }
        });
    }
    return index;
}

export const createFilterQueryString = (filters) => {
    let query = '';
    filters.map((filter, index) => {
        if(Array.isArray(filter)){
            filter.map(single => {
                query += `filters[${index}]${single}&`;
            });
        } else {
            query += `filters[${index}]${filter}&`;
        }
    });
    return query;
}

export const generateUniqueId = () => {
    return `${new Date().getTime()}-${Math.random().toString(36).slice(2, 11)}`;
};

export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};