import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Echo from "laravel-echo";

import { useLocation } from "react-router-dom";
import { getUser, hasPermission, logout } from "../../services/auth";
import Button from "../Button";
import api from "../../services/api";

export const SidebarItem = ({item, user}) => {
    const [active, setActive] = useState(false);

    const location = useLocation();
    const pathname = location.pathname;
    
    useEffect(() => {
        if(pathname == item.to){
            setActive(true);
        }
        if(item.subitems){
            item.subitems.map(subitem => {
                if(subitem.to === pathname){
                    setActive(true);
                }
            });
        }
    }, [location]);

    return (
        <li className={active ? 'active' : ''}>
            {!item.subitems && 
                <Link to={item.to}>
                    <img src={ require(`../../assets/images/svgs/${item.svg}.svg`)} alt={item.name} />
                    {item.name}
                    {item?.alert !== undefined && item?.alert !== 0 &&
                        <span className="alert">{item.alert}</span>
                    }
                </Link>
            }
            {item.subitems && 
                <a onClick={() => setActive(prev => !prev)}>
                    <img src={ require(`../../assets/images/svgs/${item.svg}.svg`)} alt={item.name} />
                    {item.name}
                    {item.subitems &&
                        <img src={ require(`../../assets/images/svgs/arrow.svg`).default} className="arrow" alt="alterar" />
                    }
                </a>
            }
            
            {item.subitems &&
                <ul>
                    {item.subitems.map((subitem, index) => {
                        if(subitem.show){
                            return (
                                <SidebarSubitem subitem={subitem} key={index} />
                            );
                        }   
                    })}
                </ul>
            }
        </li>
    );
}

export const SidebarSubitem = ({subitem}) => {
    const [active, setActive] = useState(false);

    const location = useLocation();
    
    useEffect(() => {
        if(location.pathname === subitem.to){
            setActive(true);
        }
    }, []);

    return (
        <li className={active ? 'active' : ''}>
            <Link to={subitem.to}>
                <span className="dot"></span>
                {subitem.name}
            </Link>
        </li>
    );
}


export const Sidebar = () => {
    const user = getUser();
    
    const navigate = useNavigate();
    const [quoteCount, setQuoteCount] = useState(0);
    const [items, setItems] = useState([]);

    useEffect(() => {
        getQuotes();
        const intervalId = setInterval(() => {
            getQuotes();
        }, 5000);

        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        getItems();
    }, [,quoteCount]);

    const getQuotes = () => {
        api.get(`/budget/count?filters[1][related][status.name][equal]=Aguardando orçamento`).then(res => {
            setQuoteCount(res.data.count);
        });
    }

    const signOut = e => {
        e.preventDefault();
        logout();
        navigate(`/login`);
    }

    const getItems = () => {
        const base = [
            {
                name: 'Dashboard',
                svg: 'dashboard',
                to: '/dashboard',
                show: hasPermission(['view_related_dashboard', 'view_unrelated_dashboard']),
            },
            {
                name: 'CRM',
                svg: 'crm',
                to: '/crm',
                show: true
            },
            {
                name: 'Produtos',
                svg: 'product',
                show: hasPermission(['view_product', 'view_color', 'view_category', 'view_tag', 'view_customization']),
                subitems: [
                    { 
                        name: 'Produtos', 
                        to: '/product/list',
                        show: hasPermission('view_product')
                    },
                    { 
                        name: 'Cores', 
                        to: '/color/list',
                        show: hasPermission('view_color')
                    },
                    { 
                        name: 'Categorias', 
                        to: '/category/list',
                        show: hasPermission('view_category')
                    },
                    { 
                        name: 'Selos', 
                        to: '/tag/list',
                        show: hasPermission('view_tag')
                    },
                    { 
                        name: 'Gravações', 
                        to: '/customization/list',
                        show: hasPermission('view_customization')
                    },
                ],
            },
            { 
                name: 'Empresas', 
                svg: 'company', 
                to: '/company/list',
                show: true
            },
            { 
                name: 'Pessoas', 
                svg: 'people', 
                to: '/customer/list',
                show: hasPermission(['view_unrelated_customer', 'view_related_customer'])
            },
            { 
                name: 'Orçamentos', 
                svg: 'quote', 
                to: '/quote/list', 
                alert: quoteCount,
                show: hasPermission(['view_unrelated_budget', 'view_related_budget']),
            },
            // { 
            //     name: 'Pedidos', 
            //     svg: 'order', 
            //     to: '/order/list',
            //     show: hasPermission(['view_unrelated_order', 'view_related_order'])
            // },
            {
                name: 'Conteúdo do site',
                svg: 'design',
                show: hasPermission('content'),
                subitems: [
                    // { name: 'Dados de contato', to: '/data/edit' },
                    { 
                        name: 'Links externos', 
                        to: '/variable/list',
                        show: hasPermission('site')
                    },
                    { 
                        name: 'Páginas',
                        to: '/page/list',
                        show: true
                    },
                    { 
                        name: 'Banners',
                        to: '/banner/list',
                        show: true
                    },
                    { 
                        name: 'Logos de clientes',
                        to: '/logo/list',
                        show: true
                    },
                    { 
                        name: 'Depoimentos',
                        to: '/testimonial/list',
                        show: true
                    },
                ]
            },
            { 
                name: 'Usuários', 
                svg: 'user',
                show: hasPermission(['admin', 'role']),
                subitems: [
                    { 
                        name: 'Usuários',
                        to: '/user/list',
                        show: hasPermission('admin')
                    },
                    { 
                        name: 'Níveis de acesso',
                        to: '/role/list',
                        show: hasPermission('role')
                    },
                ]
            },
            {
                name: 'Configurações',
                svg: 'config',
                show: hasPermission(['calculation', 'status', 'email_template']),
                subitems: [
                    { 
                        name: 'Cálculo', 
                        to: '/calculation',
                        show: hasPermission('calculation')
                    },
                    { 
                        name: 'Categorias dos fornecedores', 
                        to: '/company/categories',
                        show: true
                    },
                    { 
                        name: 'Status', 
                        to: '/status/list',
                        show: hasPermission('status')
                    },
                    { 
                        name: 'Templates de e-mail', 
                        to: '/email-template/list',
                        show: hasPermission('email_template')
                    }
                ]
            },
        ];

        setItems(base);
    }

    
    return (
        <aside id="sidebar">
            <div id="user">
                <p>Olá, {user?.name}</p>
                <p><a href="#" onClick={signOut}>Sair</a></p>
            </div>
            <div id="menu">
                <ul>
                    {items.map((item, index) => {
                        if(item.show){
                            return (
                                <SidebarItem item={item} key={index} user={user} />
                                );
                            }
                        })}
                </ul>
            </div>
            
        </aside>
    );
}

export default Sidebar;