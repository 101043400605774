const positionsOptions = [
    {label: "Assistente Administrativo", value: "Assistente Administrativo"},
    {label: "Analista de Compras", value: "Analista de Compras"},
    {label: "Analista Financeiro", value: "Analista Financeiro"},
    {label: "Advogado", value: "Advogado"},
    {label: "Gerente de Logística", value: "Gerente de Logística"},
    {label: "Coordenador de Marketing", value: "Coordenador de Marketing"},
    {label: "Diretor de Operações", value: "Diretor de Operações"},
    {label: "Especialista em Recursos Humanos", value: "Especialista em Recursos Humanos"},
    {label: "Analista de TI", value: "Analista de TI"},
    {label: "Executivo de Vendas", value: "Executivo de Vendas"},
    {label: "Diretor Executivo", value: "Diretor Executivo"},
    {label: "Presidente", value: "Presidente"},
    {label: "CEO", value: "CEO"},
    {label: "CFO", value: "CFO"},
    {label: "COO", value: "COO"},
    {label: "CTO", value: "CTO"},
    {label: "Gerente de Projetos", value: "Gerente de Projetos"},
    {label: "Desenvolvedor de Software", value: "Desenvolvedor de Software"},
    {label: "Engenheiro de Produto", value: "Engenheiro de Produto"}
];

export default positionsOptions;