const origins = [
    {label: "Selecione...", value: ""},
    {label: "Facebook", value: "Facebook"},
    {label: "Google", value: "Google"},
    {label: "Indicação", value: "Indicação"},
    {label: "Instagram", value: "Instagram"},
    {label: "Marketplaces", value: "Marketplaces"},
    {label: "Outbound", value: "Outbound"},
    {label: "SDR", value: "SDR"}
];

export default origins;