const segmentsOptions = [
    { label: "Advogado/Contabilidade/Consultoria/Beneficios/RH tercerizado", value: "Advogado/Contabilidade/Consultoria/Beneficios/RH tercerizado" },
    { label: "Agência de Marketing Promocional, Feiras, Congressos, Eventos", value: "Agência de Marketing Promocional, Feiras, Congressos, Eventos" },
    { label: "Agência de Publicidade/ Comunicação/MKT Digital", value: "Agência de Publicidade/ Comunicação/MKT Digital" },
    { label: "Agronegócio / Fazendas/ Comodites", value: "Agronegócio / Fazendas/ Comodites" },
    { label: "Atacadista / Distribuidora", value: "Atacadista / Distribuidora" },
    { label: "Aviação / Aeroportos", value: "Aviação / Aeroportos" },
    { label: "Construtora / incorporadora / imobiliaria", value: "Construtora / incorporadora / imobiliaria" },
    { label: "Cooperativa / Banco / Fintech /Venture Capital / Meio Pagamento", value: "Cooperativa / Banco / Fintech /Venture Capital / Meio Pagamento" },
    { label: "Educação/ Cursos/ Escola / Formatura /Treinamento", value: "Educação/ Cursos/ Escola / Formatura /Treinamento" },
    { label: "Energia / Gas", value: "Energia / Gas" },
    { label: "Fitness / Academia / Centro Esportivo /Pilates", value: "Fitness / Academia / Centro Esportivo /Pilates" },
    { label: "Governo / Prefeitura / Orgão Estadual", value: "Governo / Prefeitura / Orgão Estadual" },
    { label: "Hospital/ Laboratorio / clinica / Farmacia/ Saúde", value: "Hospital/ Laboratorio / clinica / Farmacia/ Saúde" },
    { label: "Indústria / Fabricas / Industria Quimica", value: "Indústria / Fabricas / Industria Quimica" },
    { label: "Instituto / Sindicato/ Associação/Fundação", value: "Instituto / Sindicato/ Associação/Fundação" },
    { label: "Logística / Comex /Transportadora", value: "Logística / Comex /Transportadora" },
    { label: "Outros", value: "Outros" },
    { label: "Seguradora/ Corretora", value: "Seguradora/ Corretora" },
    { label: "Tecnologia / ERP/ Softwares/ Sistemas / Big Data", value: "Tecnologia / ERP/ Softwares/ Sistemas / Big Data" },
    { label: "Turismo/ Hotelaria / Agencia Viagem", value: "Turismo/ Hotelaria / Agencia Viagem" },
    { label: "TV /Midia Nacional/ Radio / Canal You Tube", value: "TV /Midia Nacional/ Radio / Canal You Tube" },
    { label: "Varejista / Loja / E-comerce", value: "Varejista / Loja / E-comerce" }
];

export default segmentsOptions;