const departmentsOptions = [
    {label: "Compras", value: "Compras"},
    {label: "Financeiro", value: "Financeiro"},
    {label: "Jurídico", value: "Jurídico"},
    {label: "Logística", value: "Logística"},
    {label: "Marketing", value: "Marketing"},
    {label: "Operações", value: "Operações"},
    {label: "Recursos Humanos", value: "Recursos Humanos"},
    {label: "Tecnologia da Informação", value: "Tecnologia da Informação"},
    {label: "Vendas", value: "Vendas"}
];

export default departmentsOptions;